<template>
	<div class="wrapper" v-loading="loading">
		<div class="top-btn" ref="topBtn">
			<div>
				<el-select class="w110" v-model="search.search_gist" filterable placeholder="请选择" size="small">
					<el-option v-for="it in searchGist" :key="it.value" :label="it.label" :value="it.value"> </el-option>
				</el-select>
				<el-input class="w140" size="small" placeholder="请输入内容" v-model="search.desp" clearable> </el-input>
				<el-button size="small" type="primary" @click="Search()">搜索</el-button>
			</div>
			<div>
				<el-select class="w110" size="small" v-model="search.status" clearable @change="Search()">
					<el-option :key="2" :value="2" label="封停"></el-option>
					<el-option :key="1" :value="1" label="正常"></el-option>
				</el-select>
				<span class="demonstration">登录时间：</span>
				<el-date-picker size="small" style="width: 230px" @change="Search()"
					v-model="search.time"
					type="daterange"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期">
				</el-date-picker>
			</div>
		</div>
		<el-table :data="list" :max-height="tableHeight" :header-cell-style="{background:'#f5f7fa'}" style="width: 100%">
			<el-table-column prop="member_head_img" label="头像">
				<template slot-scope="scope">
					<img :src="scope.row.member_head_img || ''" style="width: 80px" alt="" />
				</template>
			</el-table-column>
			<el-table-column prop="id" label="id"></el-table-column>
			<el-table-column prop="member_name" label="昵称"></el-table-column>
			<el-table-column prop="member_phone" label="手机号"></el-table-column>
			<el-table-column prop="role_name" label="角色"></el-table-column>
			<el-table-column prop="city_name" label="城市"></el-table-column>
			<el-table-column prop="created_at" label="首次访问时间">
				<template slot-scope="scope">{{ scope.row.created_at*1000 | formatDate }}</template>
			</el-table-column>
			<el-table-column prop="login_time" label="最近登录">
				<template slot-scope="scope">{{ scope.row.login_time*1000 | formatDate }}</template>
			</el-table-column>
			<el-table-column label="状态">
				<template slot-scope="scope">
					{{ scope.row.isdel == 0 ? '正常' : '封停'}}
				</template>
			</el-table-column>
			<el-table-column prop="member_money" label="红包余额"></el-table-column>
			<el-table-column prop="ad_score" label="操作">
				<template slot-scope="scope">
					<el-link type="primary" @click="showDetail(scope.row.id)">查看详情</el-link>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination v-if="total > 0" style="padding-top: 15px; text-align: center;" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="limit" layout="prev, pager, next, jumper" :total="total">
		</el-pagination>
	</div>
</template>
<script>
	import { memberList } from '@/api/base.js';
	export default {
		name: "Userlist",
		data() {
			return {
				loading: true,
				tableHeight: this.$constant.maxHeight,
				// 列表
				searchGist:  [{
					value: 'id',
					label: 'id'
				}, {
					value: 'wx_nickname',
					label: '昵称'
				}, {
					value: 'phone',
					label: '手机号'
				}],
				search: {
					search_gist: 'wx_nickname',
					desp: '',
					time: '',
				},
				// 列表
				total: 0,
				limit: 10,
				currentPage: 1,
				list: [],
			}
		},
		mounted() {
			this.getList()
			this.tableHeight = this.$common.setHeight([this.$refs.topBtn], 130)
			window.onresize = this.$common.debounce(()=>{
				this.tableHeight = this.$common.setHeight([this.$refs.topBtn], 130)
			}, 1000)
		},
		methods: {
			// 查看详情
			showDetail(id) {
				this.$router.push({ path: '/user/userDetail', query: { group: '1', item: '1-1', id } })
			},
			// 获取列表
			getList() {
				this.loading = true
				memberList({
					limit: this.limit,
					page: this.currentPage,
					status: this.search.status,
					id: this.search.search_gist == 'id' ? this.search.desp : null,
					wx_nickname: this.search.search_gist == 'wx_nickname' ? this.search.desp : null,
					phone: this.search.search_gist == 'phone' ? this.search.desp : null,
					start_time: this.$common.setSearchTime(this.search.time)[0],
					end_time: this.$common.setSearchTime(this.search.time)[1]
				}).then((res) => {
					this.total = res.data.total;
					res.data.data.map(x => {
						x.role_name = JSON.parse(x.role_name)
					})
					this.list = res.data.data;
					this.loading = false
				}).catch((err) => {
					this.loading = false
					console.log(err, 222)
				})
			},
			// 搜索
			Search(){
				this.currentPage = 1
				this.getList()
			},
			// 页码修改
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getList();
			},
		}
	};
</script>
